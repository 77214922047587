import React from "react";
import Triangle from "../tringle/triangle";
import "./groupecard.css";
import ImageZoom from "react-medium-image-zoom";
class Groupecard extends React.Component {
  setTriangle = () => {
    if (this.props.number) {
      return (
        <Triangle
          size={this.props.size}
          number={this.props.number}
          title={this.props.title}
        />
      );
    } else {
      return null;
    }
  };

  setTitle = () => {
    if (this.props.mainTitle) {
      return <h1 className="maintitle">{this.props.mainTitle}</h1>;
    } else {
      return null;
    }
  };

  setImage = () => {
    if (this.props.picture) {
      return (
        <ImageZoom
          image={{
            src: this.props.picture,
            alt: "Energy",
            className: "img"
          }}
          zoomImage={{
            src: this.props.secpicture,
            alt: "Energy"
          }}
        />
      );
    } else return null;
  };

  render() {
    return (
      <div className="Groupecard">
        {this.setTitle()}
        <div className="maincontent">
          <div className="text-content">
            {this.setTriangle()}
            <div className="fullcontent">{this.props.children}</div>
          </div>
          <div className="pic-content">{this.setImage()}</div>
        </div>
      </div>
    );
  }
}
export default Groupecard;
