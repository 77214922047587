import React from "react";
import Description from "../../compenents/discription/Discription";
import Configurator from "../../compenents/configurator/Configurator.jsx";
import Video from "../../compenents/Video/Video";
import showreal from "../../imgs/showreal.mp4";
import "./Home.css";
import ReactGA from "react-ga";
ReactGA.initialize("UA-000000-01");
ReactGA.pageview(window.location.pathname + window.location.search);
class Body extends React.Component {
  render() {
    return (
      <div className="home">
        <Video src={showreal} />
        <div className="wraper">
          <Description />

          <Configurator />
        </div>
      </div>
    );
  }
}

export default Body;
