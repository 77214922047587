import "./Infocard.css";
import React from "react";

class Infocard extends React.Component {
  render() {
    return (
      <div className="infocard">
        <img src={this.props.picture} alt="name" />
        <p>{this.props.children}</p>
      </div>
    );
  }
}
export default Infocard;
