import React from "react";
import "./Description.css";
import $ from "jquery.animate";

class Description extends React.Component {
  componentDidMount() {
    this.setAnimationScript();
  }

  setAnimationScript() {
    $(".nav-link").click(function(e) {
      var body = $("html, body");
      body.stop().animate({ scrollTop: 0 }, 500, "swing", function() {});
    });
  }

  render() {
    return (
      <div className="desc-container text-center ">
        <h1 id="sconf_title">Offene Real-Labore </h1>
        <h2>Plattform für Mittelstand &amp; Graswurzelökonomie</h2>
      </div>
    );
  }
}

export default Description;
