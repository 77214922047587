import React from "react";
import "./Declaration.css";
import $ from "jquery.animate";

class Declaration extends React.Component {
  componentDidMount() {
    $(".colseBtn").click(function(e) {
      $("#impresum").css("display", "none");
      $("#datens").css("display", "none");
    });

    $("#imps").click(function(e) {
      $("#impresum").css("display", "block");
    });

    $("#daten").click(function(e) {
      $("#datens").css("display", "block");
    });
  }

  render() {
    return (
      <div className="declaration">
        <ul>
          <li id="imps">Impressum</li>
          <li id="daten">Datenschutzerklärung</li>
        </ul>
      </div>
    );
  }
}

export default Declaration;
