import React from "react";
import ServiceDetailleInv from "../../compenents/services/ServiceDetailleInv";
import { NavLink } from "react-router-dom";
import $ from "jquery.animate";

import fc from "../../imgs/Services/fc.jpeg";
import ds from "../../imgs/Services/ds.png";
import ds1 from "../../imgs/Services/ds1.png";
import ds2 from "../../imgs/Services/ds2.png";
import ds3 from "../../imgs/Services/ds3.png";
import co1 from "../../imgs/Services/co1.png";
import co2 from "../../imgs/Services/co2.png";
import co3 from "../../imgs/Services/co3.png";
import in1 from "../../imgs/Services/in1.png";
import in2 from "../../imgs/Services/in2.png";
import in3 from "../../imgs/Services/in3.png";
//--------------
import Popmodel from "./modal";
//--------------
import Carte from "../../compenents/carte/Carte";
import "./Services.css";

class Services extends React.Component {
  componentDidMount() {
    this.setAnimationScript();
  }

  setAnimationScript() {
    $(".energie-section").click(function(e) {
      var body = $("html, body");
      body.stop().animate({ scrollTop: 0 }, 500, "swing", function() {});
    });
  }

  render() {
    return (
      <div id="Services">
        <div className="wraper">
          <ServiceDetailleInv
            title="Entwicklerkompetenz für digitale Medientechnik"
            picture={ds}
            left={
              <p>
                Wir bieten digitale Services für die Weiterentwicklung Ihrer
                Produkte und Leistungen. Auf Basis bestehender Kompetenzen und
                Produktionsmitteln platzieren wir diese erfolgreich in
                zukunftsfähigen Märkten.
              </p>
            }
            ritgh={
              <React.Fragment>
                <Carte
                  title=""
                  picture={ds1}
                  content={
                    <div>
                      <p>
                        STEIDINGER tritt auf als Anbieter oder externer
                        Dienstleister für Softwareentwicklung – und – als
                        neutraler Vermittlungspartner für viele Spezialbereiche
                        in der Schnittstelle zum Maschinenbau. Wir verstehen uns
                        als IT- und Technologie-Hub mit internationalen
                        Fachkräften in Algerien und einer leistungsstarken
                        Koordination vor Ort in St. Georgen.
                      </p>
                      <Popmodel
                        text="Lernen Sie
                        unsere attraktiven Konditionen kennen"
                      />
                    </div>
                  }
                />

                <Carte
                  title=""
                  picture={ds2}
                  content={
                    <div>
                      <p>
                        Die Teilnahme an den Real-Laboren beinhaltet optional
                        die direkte mediale Dokumentation von neuen Anwendungen
                        und Produktideen. Künstler, Pioniere, Querdenker und
                        Kleinproduzenten entwickeln experimentell an Ihrem
                        Produkt und setzen es für neue Märkte und Verkaufskanäle
                        in Szene.
                      </p>
                    </div>
                  }
                />

                <Carte
                  title=""
                  picture={ds3}
                  content={
                    <p>
                      Dafür übernehmen wir selbst oder über Partner die
                      vollständige Inverkehrbringung in Konsumentenmärkten, sei
                      es durch Veröffentlichung auf Plattformen für Crowdfunding
                      oder digitalen Handel. Bei Investitionsgütern agieren wir
                      als Handelsvertretung auf Provisionsbasis.
                    </p>
                  }
                />
                <div className="clear-both"></div>
              </React.Fragment>
            }
          />

          <ServiceDetailleInv
            title="Breitangelegte Kollaboration"
            picture={co1}
            left={
              <p>
                Unsere Real-Labore und Residenzprogramme in St. Georgen und
                Umgebung sind unsere physischen Anlaufststationen für Innovation
                &amp; Vernetzung in Form von Co-Working &amp; Co-Making.
              </p>
            }
            ritgh={
              <React.Fragment>
                <Carte
                  title=""
                  picture={co1}
                  content={
                    <p>
                      Wir vermieten Kreativräume und Labors in dezentralen
                      offenen Fabriken, Werkstätten und Ateliers in St. Georgen.
                      Regelmäßig oder komprimiert, maßgeschneidert je nach
                      Alter, Gruppengröße, Jahreszeit und natürlich entsprechend
                      Ihrem Anlass. Auf Wunsch mit Verpflegung und
                      Rahmenprogramm, mit spezieller Technik oder mit einer
                      Selektion unserer Fachexperten.
                    </p>
                  }
                />

                <Carte
                  title=""
                  picture={co2}
                  content={
                    <p>
                      Kooperierende Vereine, Förderinstitutionen und Unternehmen
                      stiften oder stellen Plätze im Residenzprogramm oder im
                      Real-Labor – und entwickeln damit potentielle lokale und
                      internationale Fachkräfte, speziell in Mangelberufen wie
                      z.B. Digitalisierung. Gemeinsam entsteht Attraktivität für
                      den Einbezug von ehrenamtlichen Mentoren, die Ihr Wissen
                      in eine nachhaltige Entwicklung investieren möchten.
                    </p>
                  }
                />

                <Carte
                  title=""
                  picture={co3}
                  content={
                    <p>
                      Unsere „Walzmannschaft“ aus den Fachgebieten Kulinarik,
                      Gastgewerbe, Handwerk, Kunst und Design bringen wir in
                      persönliche Beziehungen mit Wirtschaft, Wissenschaft,
                      Bildung und Politik, z.B. mit Fördermitteln. Gemeinsam
                      entwickeln wir unsere Büro- und Residenzstandorte ständig
                      weiter zu einem attraktiven Wohn- und Arbeitsumfeld für
                      urbane Zielgruppen im Alter von 25 bis 45.
                    </p>
                  }
                />
                <div className="clear-both"></div>
              </React.Fragment>
            }
          />
          <ServiceDetailleInv
            title="Zukunftsfähigkeit für die mittelständische Industrie"
            picture={fc}
            left={
              <p>
                Geschäftsfeldentwicklung ist der Grundstein unseres Engagements
                mit unseren vielseitigen Kunden und Partnern.
              </p>
            }
            ritgh={
              <React.Fragment>
                <Carte
                  title=""
                  picture={in1}
                  content={
                    <p>
                      Wir forschen, entwickeln und produzieren für die Märkte
                      <NavLink className="energie-section" exact to="/energie">
                        &nbsp;Energie
                      </NavLink>
                      , Lebensmittel- &amp; Medientechnik. „Wir“ sind Gründer,
                      Macher und Pioniere, deren Motivation aus Idealismus und
                      Leidenschaft besteht. Wir verbünden uns auf dieser
                      Plattform mit verschiedensten Akteuren, um nachhaltigen
                      Mehrwert zu schaffen.
                    </p>
                  }
                />

                <Carte
                  title=""
                  picture={in2}
                  content={
                    <p>
                      Als Kooperationspartner oder als treibende Kraft bauen wir
                      Schwarmintelligenz auf. Wir öffnen dabei Türen in
                      öffentliche, wissenschaftliche und soziale Bereiche – bei
                      Bedarf auch hin zu sub- oder interkulturellen Communities
                      wie z.B. Open Source.
                    </p>
                  }
                />

                <Carte
                  title=""
                  picture={in3}
                  content={
                    <p>
                      Industriell geprägte Familienunternehmen verstehen und
                      öffnen durch uns Vertriebswege und Geschäftsfelder in
                      neuen und zukunftsfähigen Märkten – unter Beibehaltung der
                      langjährigen Kernkompetenz und mit ihren vertrauten
                      Geschäftspartnern.
                    </p>
                  }
                />

                <div className="clear-both"></div>
              </React.Fragment>
            }
          />
        </div>
      </div>
    );
  }
}

export default Services;
