import React from "react";
import ServiceDetailleInv from "../../compenents/services/ServiceDetailleInv";
import Carte from "../../compenents/carte/Carte";

import "./for_tallents.css";

class for_tallents extends React.Component {
  render() {
    return (
      <div id="for-tallent">
        <div className="wraper">
          <ServiceDetailleInv
            title="Für Talente"
            left={
              <p>
                Wo ist der Benefit von Steidinger? Wie profitieren die Partner
                bei diesem Geschäftsmodell? ​{" "}
              </p>
            }
            ritgh={
              <div>
                <Carte title="">
                  STEIDINGER tritt auf als Softwareentwickler bzw. Dienstleister
                  für digitale Services – und – als neutraler
                  Vermittlungspartner für Technologietransfer. Als
                  Konsortialführer oder als treibende Kraft bilden wir
                  Kooperationen und öffnen Türen in den öffentlichen Sektor.
                  Dafür übernehmen wir die vollständige Inverkehrbringung in
                  Konsumentenmärkten – bei Investitionsgütern agieren wir als
                  Handelsvertretung auf Provisionsbasis​.
                </Carte>

                <Carte title="">
                  Unsere Partner aus dem Mittelstand profitieren durch die
                  maßgeschneiderte Vermittlung von attraktiven Ressourcen aus
                  Bildung, Politik (z.B. Fördermittel), Wissenschaft und Kunst
                  sowie speziell Fachkräfte und Leistungen für Digitalisierung.
                </Carte>

                <Carte title="">
                  Somit kann ein industriell geprägter Mittelständler neue
                  Vertriebswege und Geschäftsfelder oder z.B. ein
                  Jubiläumsgeschenk entwickeln und aufbauen, ohne die Energie
                  ausschließlich intern aufwenden zu müssen und die
                  erforderlichen knappen Fachkräfte zu rekrutieren. Denn der
                  Fokus auf die eigene Kernkompetenz verträgt sich gerade bei
                  hochspezialisierten Technologiefirmen immer weniger mit einer
                  Offenheit gegenüber Zukunftsthemen. ​
                </Carte>

                <div className="clear-both"></div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default for_tallents;
