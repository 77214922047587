import React from "react";
import "./Member.css";

class Member extends React.Component {
  render() {
    return (
      <div className="member">
        <img
          className="picture"
          src={this.props.picture}
          alt="member"
          align="center"
          height="200"
          width="200"
        />
        <div className="info">
          <p>{this.props.children}</p>
          {/*  <p><span><FaPhoneSquare/></span> +491786695049</p>
            <p><span><IoIosMail/></span> sh@steidinger.audio</p>*/}
        </div>
      </div>
    );
  }
}

export default Member;
