import React from 'react';
import './Conact.css';
import MyMap from '../googleMap/MyMap';

class Contact extends React.Component{

    render(){
       return (
           <div className="contacts">
               
               <div className="wraper">
               <div id="mapi">
                       <MyMap/>
                    </div>
                      <div id="info">
                        <h1>KontaKt</h1>
                        <p>8 Bahnhofstraße
                            St. Georgen, 78112
                            Deutschland <br/>info@steidinger.audio <br/>+49 (0) 123 456 78</p>
                    </div>
                    <div id="formu">
                       <form>
                       <input className="inputs" type="text" name="name" placeholder="Name"/><input className="inputs" type="email" name="email" placeholder="Email"/><br/>
                       <input className="inputs" type="text" name="subject" placeholder="Subject"/><br/>
                       <input className="inputs" type="tel" name="phone" placeholder="Phone number"/><br/>
                       <input className="inputs" type="text" name="adresse" placeholder="Adresse"/><br/>
                       <textarea className="inputs" rows="4" cols="50" name="message" placeholder="Message">
                        </textarea>
                        <button type="submit">Senden</button>
                       </form>
                    </div>
                    <div className="clear-both"></div>
               </div>
           </div>
       ) 
    }
}

export default Contact;