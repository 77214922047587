import React from "react";
import "./navStyle.css";
import $ from "jquery.animate";
import { MdMenu } from "react-icons/md";
import logo from "./nav-img/logo.png";

import { NavLink } from "react-router-dom";

class Nav extends React.Component {
  componentDidMount() {
    // this.setScrollEffect()
    this.setAnimationScript();
  }

  setScrollEffect() {
    window.addEventListener("scroll", function(e) {
      this.console.log(this);
      var scrollValue = $(window).scrollTop();
      if (scrollValue !== 0) {
        $("header").animate(
          {
            backgroundColor: "rgba(0, 0, 0, 0.5)"
          },
          function() {
            $("header").stop(true, true);
          }
        );
      } else {
        $("header").css({
          backgroundColor: "rgba(0, 0, 0, 0)"
        });
      }
    });
  }

  setAnimationScript() {
    $(".nav-link").click(function(e) {
      var body = $("html, body");
      body.stop().animate({ scrollTop: 0 }, 500, "swing", function() {});
    });

    $("body").click(function(e) {
      var left = $("#full").css("left");

      if (left === "-250px") {
      } else {
        $("#full").animate({
          left: "-250px",
          transform: " skewY(50deg)"
        });
      }
    });

    $("#toogle-btn").click(function(e) {
      var left = $("#full").css("left");

      if (left === "-250px") {
        $("#full").animate(
          {
            left: "0px",
            transform: " skewY(0deg)"
          },
          function() {}
        );
      } else {
        $("#full").animate({
          left: "-250px",
          transform: " skewY(50deg)"
        });
      }
    });
  }

  render() {
    return (
      <div>
        <header>
          <div id="toogle-btn">
            <h1>
              <MdMenu />
            </h1>{" "}
          </div>

          <div id="logo">
            <NavLink className="nav-link" exact to="/">
              <img src={logo} alt="Logo Steidinger" />
              <h1>Steidinger</h1>
            </NavLink>
          </div>

          <nav>
            <ul>
              <li>
                <NavLink className="nav-link" exact to="/">
                  Start
                </NavLink>
              </li>

              <li id="energirtechnik">
                <NavLink className="nav-link" exact to="/Energie">
                  REAL-LABORE
                </NavLink>
                <ul id="energirtechniksublink">
                  <li>
                    <NavLink className="nav-link sublink" exact to="/Energie">
                      Energietechnik
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="nav-link" exact to="/Services">
                  Für Unternehmen​
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" exact to="/About">
                  über uns
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>

        <div id="full">
          <NavLink className="nav-link" exact to="/">
            Start
          </NavLink>
          <NavLink className="nav-link" exact to="/Energie">
            REAL-LABORE
          </NavLink>
          <div className="respo-submenu">
            <NavLink className="nav-link" exact to="/Energie">
              Energietechnik
            </NavLink>
          </div>
          <NavLink className="nav-link" exact to="/Services">
            Für Unternehmen​
          </NavLink>
          <NavLink className="nav-link" exact to="/About">
            über uns
          </NavLink>
        </div>
      </div>
    );
  }
}

export default Nav;
