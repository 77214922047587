import React from "react";
class Video extends React.Component {
  render() {
    return (
      <div>
        <div style={{ overflow: "hidden", width: "100%", height: "auto" }}>
          <video autoPlay muted loop width="100%" height="auto">
            <source src={this.props.src} type="video/mp4" />
          </video>
        </div>
      </div>
    );
  }
}
export default Video;
