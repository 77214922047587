import React from "react";
import emailjs from "emailjs-com";
import Waiting from "../waiting/Waiting";
import "./formula.css";
class Formula extends React.Component {
  constructor(props) {
    super(props);
    emailjs.init("user_SCdSH8eyg8r0UzAWVp8uv");
    this.state = {
      name: "",
      email: "",
      topic: "",
      phone: "",
      message: "",
      display: "none",
      className: "alert alert-success",
      send_message: "Ihre Nachricht wurde erfolgreich übermittelt !",
      process: false
    };
  }
  waiting = function() {
    if (this.state.process) {
      return <Waiting />;
    } else {
      return (
        <div
          className={this.state.className}
          style={{ display: this.state.display }}
          role="alert"
        >
          {this.state.send_message}
        </div>
      );
    }
  };
  submitemail = event => {
    event.preventDefault();
    this.setState({ process: true });
    emailjs
      .sendForm("Gmail", "template_GAwMFxQO", document.getElementById("cform"))
      .then(
        res => {
          this.setState({
            display: "block",
            name: "",
            email: "",
            topic: "",
            phone: "",
            message: "",
            process: false
          });
        },
        err => {
          this.setState({
            display: "block",
            className: "alert alert-danger",
            process: false,

            send_message:
              "Die Nachricht konnte leider nicht übermittelt werden. Bitte versuchen Sie es erneut zu einem späteren Zeitpunkt."
          });
        }
      );
  };
  render() {
    return (
      <div id="contact-form">
        {this.waiting()}
        <form id="cform" onSubmit={this.submitemail}>
          <input
            required
            className="inputs"
            type="text"
            name="user_name"
            placeholder="Name"
            value={this.state.name}
            onChange={e => {
              this.setState({ name: e.target.value });
            }}
          />
          <input
            required
            className="inputs"
            type="email"
            name="user_email"
            placeholder="Email"
            value={this.state.email}
            onChange={e => {
              this.setState({ email: e.target.value });
            }}
          />
          <br />
          <input
            required
            className="inputs"
            type="text"
            name="user_thema"
            placeholder="Thema"
            value={this.state.topic}
            onChange={e => {
              this.setState({ topic: e.target.value });
            }}
          />
          <br />
          <input
            required
            className="inputs"
            type="tel"
            name="user_phone"
            placeholder="Telefonnummer"
            value={this.state.phone}
            onChange={e => {
              this.setState({ phone: e.target.value });
            }}
          />
          <br />
          <textarea
            required
            className="inputs"
            rows="4"
            cols="50"
            name="message"
            placeholder="Nachricht"
            value={this.state.message}
            onChange={e => {
              this.setState({ message: e.target.value });
            }}
          />
          <input id="btn" type="submit" value="Senden" />
        </form>
      </div>
    );
  }
}

export default Formula;
