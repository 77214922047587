import React from "react";
import "./ServiceDetailleInv.css";

class ServiceDetailleInv extends React.Component {
  render() {
    return (
      <div>
        <div className="serviceDetailleInv">
          <div className="sd-description">
            <h1>{this.props.title}</h1>
            <div>{this.props.left}</div>
          </div>
          <div className="sd-content">{this.props.ritgh}</div>
        </div>
      </div>
    );
  }
}

export default ServiceDetailleInv;
