import React from "react";
import "./Content.css";

const Daten = function() {
  return (
    <div id="datens">
      <button className="colseBtn">X</button>

      <h1>Datenschutzerklärung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen zum
        Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h3>Datenerfassung auf unserer Website</h3>
      <p>
        <strong>
          Wer ist verantwortlich für die Datenerfassung auf dieser Website?
        </strong>
      </p>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>
      <p>
        <strong>Wie erfassen wir Ihre Daten?</strong>
      </p>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
        Kontaktformular eingeben.
      </p>
      <p>
        Andere Daten werden automatisch beim Besuch der Website durch unsere
        IT-Systeme erfasst. Das sind vor allem technische Daten (z.B.
        Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
        Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
        betreten.
      </p>
      <p>
        <strong>Wofür nutzen wir Ihre Daten?</strong>
      </p>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <p>
        <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
      </p>
      <p>
        Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder
        Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum
        Thema Datenschutz können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Außerdem haben Sie das Recht, unter bestimmten Umständen die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen. Details hierzu entnehmen Sie der Datenschutzerklärung unter
        „Recht auf Einschränkung der Verarbeitung“.
      </p>
      <h3>Analyse-Tools und Tools von Drittanbietern</h3>
      <p>
        Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch
        ausgewertet werden. Das geschieht vor allem mit Cookies und mit
        sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt
        in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen
        zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie
        durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
        Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
      </p>
      <p>
        Sie können dieser Analyse widersprechen. Über die
        Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
        informieren.
      </p>
      <h2>2. Allgemeine Hinweise und Pflichtinformationen</h2>
      <h3>Datenschutz</h3>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h3>Hinweis zur verantwortlichen Stelle</h3>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
        <br />
        Steidinger Apparatebau GmbH
        <br />
        Bahnhofstraße 8<br />
        78112 St. Georgen
        <br />
        Telefon: +49 015 172 788 888
        <br />
        E-Mail: info@steidinger.audio
        <br />
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
        Ä.) entscheidet.
      </p>
      <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <h3>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h3>
      <p>
        <strong>
          Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder
          f DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich
          aus Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
          personenbezogenen Daten Widerspruch einzulegen; dies gilt auch für ein
          auf diese Bestimmungen gestütztes Profiling. Die jeweilige
          Rechtsgrundlage, auf denen eine Verarbeitung beruht, entnehmen Sie
          dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden wir
          Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
          sei denn, wir können zwingende schutzwürdige Gründe für die
          Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
          überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung
          oder Verteidigung von Rechtsansprüchen (Widerspruch nach Art. 21 Abs.
          1 DSGVO).
        </strong>
      </p>
      <p>
        <strong>
          Werden Ihre personenbezogenen Daten verarbeitet, um Direktwerbung zu
          betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die
          Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke
          derartiger Werbung einzulegen; dies gilt auch für das Profiling,
          soweit es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
          widersprechen, werden Ihre personenbezogenen Daten anschließend nicht
          mehr zum Zwecke der Direktwerbung verwendet (Widerspruch nach Art. 21
          Abs. 2 DSGVO).
        </strong>
      </p>
      <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h3>Recht auf Datenübertragbarkeit</h3>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <h3>SSL- bzw. TLS-Verschlüsselung</h3>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von “http://” auf “https://” wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h3>Auskunft, Sperrung, Löschung und Berichtigung</h3>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
      </p>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
        Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>
      <ul>
        <li>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah / geschieht, können Sie statt der Löschung die Einschränkung
          der Datenverarbeitung verlangen.
        </li>
        <li>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
        </li>
        <li>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
        </li>
      </ul>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <h2>3. Datenerfassung auf unserer Website</h2>
      <h3>Cookies</h3>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert.
      </p>
      <p>
        Die meisten der von uns verwendeten Cookies sind so genannte
        “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch
        gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie
        diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim
        nächsten Besuch wiederzuerkennen.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
        (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
        andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>
      <h3>Kontaktformular</h3>
      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne
        Ihre Einwilligung weiter.
      </p>
      <p>
        Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt
        somit ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen. Dazu
        reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
        der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
        Widerruf unberührt.
      </p>
      <p>
        Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>
      <h2>4. Analyse-Tools und Werbung</h2>
      <h3>Google Analytics</h3>
      <p>
        Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
        Anbieter ist die Google Ireland Limited (“Google”), Gordon House, Barrow
        Street, Dublin 4, Irland.
      </p>
      <p>
        Google Analytics verwendet so genannte "Cookies". Das sind Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Website durch Sie ermöglichen. Die durch den Cookie
        erzeugten Informationen über Ihre Benutzung dieser Website werden in der
        Regel an einen Server von Google in den USA übertragen und dort
        gespeichert.
      </p>
      <p>
        Die Speicherung von Google-Analytics-Cookies und die Nutzung dieses
        Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
        optimieren.
      </p>
      <p>
        <strong>Browser Plugin</strong>
      </p>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können darüber hinaus die Erfassung der durch den Cookie erzeugten und
        auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
        Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
        herunterladen und installieren:{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Widerspruch gegen Datenerfassung</strong>
      </p>
      <p>
        Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
        Website verhindert: <span>Google Analytics deaktivieren</span>.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
        finden Sie in der Datenschutzerklärung von Google:{" "}
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/6004245?hl=de
        </a>
        .
      </p>
      <p>
        <strong>Speicherdauer</strong>
      </p>
      <p>
        Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die mit
        Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
        DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach 14
        Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie unter
        folgendem Link:{" "}
        <a
          href="https://support.google.com/analytics/answer/7667196?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://support.google.com/analytics/answer/7667196?hl=de
        </a>
      </p>
      <h3>WordPress Stats</h3>
      <p>
        Diese Website nutzt das WordPress Tool Stats, um Besucherzugriffe
        statistisch auszuwerten. Anbieter ist die Automattic Inc., 60 29th
        Street #343, San Francisco, CA 94110-4929, USA.
      </p>
      <p>
        WordPress Stats verwendet Cookies, die auf Ihrem Computer gespeichert
        werden und die eine Analyse der Benutzung der Website erlauben. Die
        durch die Cookies generierten Informationen über die Benutzung unserer
        Website werden auf Servern in den USA gespeichert. Ihre IP-Adresse wird
        nach der Verarbeitung und vor der Speicherung anonymisiert.
      </p>
      <p>
        “WordPress-Stats”-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
        löschen.
      </p>
      <p>
        Die Speicherung von “WordPress Stats”-Cookies und die Nutzung dieses
        Analyse-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
        Websitebetreiber hat ein berechtigtes Interesse an der anonymisierten
        Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
        Werbung zu optimieren.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        unserer Website eingeschränkt sein.
      </p>
      <p>
        Sie können der Erhebung und Nutzung Ihrer Daten für die Zukunft
        widersprechen, indem Sie mit einem Klick auf diesen Link einen
        Opt-Out-Cookie in Ihrem Browser setzen:{" "}
        <a
          href="https://www.quantcast.com/opt-out/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.quantcast.com/opt-out/
        </a>
        .
      </p>
      <p>
        Wenn Sie die Cookies auf Ihrem Rechner löschen, müssen Sie den
        Opt-Out-Cookie erneut setzen.
      </p>
      <h3>Google Analytics Remarketing</h3>
      <p>
        Unsere Websites nutzen die Funktionen von Google Analytics Remarketing
        in Verbindung mit den geräteübergreifenden Funktionen von Google AdWords
        und Google DoubleClick. Anbieter ist die Google Ireland Limited
        (“Google”), Gordon House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Diese Funktion ermöglicht es die mit Google Analytics Remarketing
        erstellten Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
        Google AdWords und Google DoubleClick zu verknüpfen. Auf diese Weise
        können interessenbezogene, personalisierte Werbebotschaften, die in
        Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
        Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem anderen
        Ihrer Endgeräte (z.B. Tablet oder PC) angezeigt werden.
      </p>
      <p>
        Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu
        diesem Zweck Ihren Web- und App-Browserverlauf mit Ihrem Google-Konto.
        Auf diese Weise können auf jedem Endgerät auf dem Sie sich mit Ihrem
        Google-Konto anmelden, dieselben personalisierten Werbebotschaften
        geschaltet werden.
      </p>
      <p>
        Zur Unterstützung dieser Funktion erfasst Google Analytics
        google-authentifizierte IDs der Nutzer, die vorübergehend mit unseren
        Google-Analytics-Daten verknüpft werden, um Zielgruppen für die
        geräteübergreifende Anzeigenwerbung zu definieren und zu erstellen.
      </p>
      <p>
        Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft
        widersprechen, indem Sie personalisierte Werbung in Ihrem Google-Konto
        deaktivieren; folgen Sie hierzu diesem Link:{" "}
        <a
          href="https://www.google.com/settings/ads/onweb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.google.com/settings/ads/onweb/
        </a>
        .
      </p>
      <p>
        Die Zusammenfassung der erfassten Daten in Ihrem Google-Konto erfolgt
        ausschließlich auf Grundlage Ihrer Einwilligung, die Sie bei Google
        abgeben oder widerrufen können (Art. 6 Abs. 1 lit. a DSGVO). Bei
        Datenerfassungsvorgängen, die nicht in Ihrem Google-Konto
        zusammengeführt werden (z.B. weil Sie kein Google-Konto haben oder der
        Zusammenführung widersprochen haben) beruht die Erfassung der Daten auf
        Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse ergibt sich
        daraus, dass der Websitebetreiber ein Interesse an der anonymisierten
        Analyse der Websitebesucher zu Werbezwecken hat.
      </p>
      <p>
        Weitergehende Informationen und die Datenschutzbestimmungen finden Sie
        in der Datenschutzerklärung von Google unter:{" "}
        <a
          href="https://policies.google.com/technologies/ads?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/technologies/ads?hl=de
        </a>
        .
      </p>
      <h3>Google AdWords und Google Conversion-Tracking</h3>
      <p>
        Diese Website verwendet Google AdWords. AdWords ist ein
        Online-Werbeprogramm der Google Ireland Limited (“Google”), Gordon
        House, Barrow Street, Dublin 4, Irland.
      </p>
      <p>
        Im Rahmen von Google AdWords nutzen wir das so genannte
        Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige
        klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies
        handelt es sich um kleine Textdateien, die der Internet-Browser auf dem
        Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre
        Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer.
        Besucht der Nutzer bestimmte Seiten dieser Website und das Cookie ist
        noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer
        auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
      </p>
      <p>
        Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können
        nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die
        mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
        Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für
        Conversion-Tracking entschieden haben. Die Kunden erfahren die
        Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu
        einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet
        wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer
        persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen
        möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie
        des Google Conversion-Trackings über ihren Internet-Browser unter
        Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die
        Conversion-Tracking Statistiken aufgenommen.
      </p>
      <p>
        Die Speicherung von “Conversion-Cookies” und die Nutzung dieses
        Tracking-Tools erfolgen auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO.
        Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des
        Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu
        optimieren.
      </p>
      <p>
        Mehr Informationen zu Google AdWords und Google Conversion-Tracking
        finden Sie in den Datenschutzbestimmungen von Google:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browser
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <h2>5. Plugins und Tools</h2>
      <h3>Google Web Fonts</h3>
      <p>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
        genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf
        einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren
        Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
      </p>
      <p>
        Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
        Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber,
        dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung
        von Google Web Fonts erfolgt im Interesse einer einheitlichen und
        ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
        von Ihrem Computer genutzt.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
        <a
          href="https://developers.google.com/fonts/faq"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://developers.google.com/fonts/faq
        </a>{" "}
        und in der Datenschutzerklärung von Google:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
      <h3>Google Maps</h3>
      <p>
        Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter
        ist die Google Ireland Limited (“Google”), Gordon House, Barrow Street,
        Dublin 4, Irland.
      </p>
      <p>
        Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
        Adresse zu speichern. Diese Informationen werden in der Regel an einen
        Server von Google in den USA übertragen und dort gespeichert. Der
        Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
      </p>
      <p>
        Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
        Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
        der von uns auf der Website angegebenen Orte. Dies stellt ein
        berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      </p>
      <p>
        Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
        Datenschutzerklärung von Google:{" "}
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy?hl=de
        </a>
        .
      </p>
    </div>
  );
};
export default Daten;
