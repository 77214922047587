import React from "react";
import "./About.css";
import Member from "../../compenents/member/Member";
import Formula from "../../compenents/formula/Formula";
import hacini from "../../imgs/members/hacini.jpg";
import florian from "../../imgs/members/florian.jpg";
import hans from "../../imgs/members/hans.jpg";
import walter from "../../imgs/members/walter.jpg";
import marsha from "../../imgs/members/marsha.jpg";
import Picture from "../../compenents/Pictures/Picture";
import ub1 from "../../imgs/aboutus/ub1.png";
import ub2 from "../../imgs/aboutus/ub2.jpg";
import ub3 from "../../imgs/aboutus/ub3.png";
import ub4 from "../../imgs/aboutus/ub4.png";
import ub5 from "../../imgs/aboutus/ub5.png";
import ub6 from "../../imgs/aboutus/ub6.png";
import ub7 from "../../imgs/aboutus/ub7.png";
import ub8 from "../../imgs/aboutus/ub8.png";
import ub9 from "../../imgs/aboutus/ub9.png";
import ub10 from "../../imgs/aboutus/ub10.png";
import ub11 from "../../imgs/aboutus/ub11.png";
import ub12 from "../../imgs/aboutus/ub12.png";
import MyMap from "../../compenents/googleMap/MyMap";

class Body extends React.Component {
  render() {
    return (
      <div id="about">
        <div className="wraper">
          <section className="description">
            <h3>Beschreibung</h3>
            <p>
              Wir sind ein junges Startup aus St. Georgen im Schwarzwald und
              bilden eine überregionale, integrative Plattform zwischen
              mittelständischen Unternehmen und der Graswurzelbewegung. ​
            </p>
            <p>
              Derzeit arbeiten wir mit ca. 10 angestellten oder selbständigen
              Fachkräften: Feinkost-Hersteller, Designer, Tourismus-Experten,
              HiFi-Elektronik-Entwickler und Ingenieure für Audio, Maschinenbau,
              erneuerbare Energien und Softwareprogrammierung​
            </p>

            <p>
              Dank partnerschaftliche Entwicklungshilfe- und
              Ausbildungsprogramme in Algerien, Indonesien, Nepal oder Guatemala
              pflegt STEIDINGER internationale Kooperationen in Kulinarik,
              Gastgewerbe, Handwerk, Kunst, Design und Maschinenbau. ​
            </p>

            <p>
              Am Standort Fabrik Obergfell entsteht die Plattform in Form von
              einer offenen Bürolandschaft und drei Real-Laboren für
              Energietechnik, Kulinarik und Digitale Medien.
            </p>
            <div className="wraper">
              <Picture src={ub1} />
              <Picture src={ub2} />
              <Picture src={ub3} />
              <Picture src={ub4} />
              <Picture src={ub5} />
              <Picture src={ub6} />
              <Picture src={ub7} />
              <Picture src={ub8} />
              <Picture src={ub9} />
              <Picture src={ub10} />
              <Picture src={ub11} />
              <Picture src={ub12} />
            </div>

            <p>
              100% Tochtergesellschaft von Weisser Haas GmbH /
              Einzelgesellschafter Hansjörg Weisser, aber…​
            </p>
            <p style={{ paddingLeft: "50px" }}>
              - Die Vermögensstrategie ist zum Streubesitz angelegt und über
              Beteiligungen an Immobilien wie dem Offenen Fabrikquartier
              Kippenberger (internationale Residenzen und Labore für
              Gastgewerbe, Handwerk und Design) in der Friedrichstr. 5a. In St.
              Georgen abgesichert. ​
            </p>
            <p style={{ paddingLeft: "50px" }}>
              - Anteile für Gewinnverwendungsrechte bestehen für alle
              Mitgründer, Mitarbeiter, enge Partner sowie für alle entfernt
              verwandten und angeheirateten Familien. ​
            </p>
            <p style={{ paddingLeft: "50px" }}>
              - Die Familie Steidinger ist die hinsichtlich Unternehmensgröße
              und Personenzahl bisher unübertroffen größte Unternehmerfamilie
              St. Georgens, die sozusagen zentral mit den meisten anderen
              mittelständischen Unternehmen über Heirat verbunden ist.
            </p>
          </section>

         {/**  <section className="team">
            <h3>Team</h3>
            <div className="wraper">
              <Member picture={hans}>Hansjörg Weisser</Member>
            </div>
          </section>
          */}
          <section>
            <MyMap isMarkerShown />
          </section>
          <section className="team">
            <h3>Irgendwelche Fragen?</h3>
            <div className="wraper">
              <Formula />
            </div>
          </section>

          <div className="clear-both"></div>
        </div>
      </div>
    );
  }
}

export default Body;
