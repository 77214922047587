import React from "react";
import "./energie.css";
import Groupecard from "../../compenents/groupecard/groupecard";
import Infocard from "../../compenents/infocard/infocard";
import steidingerlogo from "../../imgs/logos/steidinger.png";
import sunorbitlogo from "../../imgs/logos/sunorbit.png";
import mainimage from "../../imgs/energy/mainimage.jpeg";
import pic1 from "../../imgs/energy/1.png";
import pic2 from "../../imgs/energy/2.png";
import pic3 from "../../imgs/energy/3.png";
import pic4 from "../../imgs/energy/4.png";
import pic6 from "../../imgs/energy/6.png";
import pic7 from "../../imgs/energy/7.png";
class energie extends React.Component {
  render() {
    return (
      <div id="energie">
        <div className="wraper">
          <div className="pictureshow">
            <img src={mainimage} alt="main" />>
          </div>
          <div className="owners">
            <h1>Energiebaukasten der Kooperationspartner</h1>
            <div id="owner-info">
              <Infocard picture={sunorbitlogo}>
                Eine Entwicklungsfirma, die im Bereich der Solarenergie mehrere
                Entwicklungen von Professor Jürgen Kleinwächter mithilfe des
                hochautomatisierten Metallbau-Spezialisten STEINHART zur
                Produktreife gebracht hat.
              </Infocard>
              <Infocard picture={steidingerlogo}>
                Eine internationale &amp; interkommunale Plattform zwischen
                mittelständischen Firmen wie dem nahe stehenden Maschinenbauer
                für Großserie J.G.WEISSER zur Entwicklung von neuen
                Geschäftsfeldern &amp; Kooperationen mit Basisbewegungen
                (Erfinder, Forscher, Bürger, Netzwerke &amp; Communities…)
              </Infocard>
            </div>
          </div>
          <Groupecard number="01" title="Energie-Gewächshaus" picture={pic1}>
            <h3>BENEFITS</h3>
            <ul>
              <li>Thermoelektrisches Energiegewächshaus</li>
              <li>Variable Skalierung möglich</li>
              <li>Leichtbau ► Aufstockung von Gebäuden</li>
            </ul>
            <h3>FAKTEN</h3>
            <ul>
              <li>Effizientere Flächennutzung als PV</li>
              <li>
                Regelbare Wärmeausleitung
                <ul>
                  <li>Besseres Pflanzenwachstum</li>
                  <li>Geringerer Wasserverbrauch</li>
                  <li> Ideale Bedingungen für Mensch &amp; Pflanze</li>
                </ul>
              </li>

              <li>
                Ultraleichtfolie mit UV-Durchlässigkeit
                <ul>
                  <li> Keine Pestizide</li>
                  <li>Längere Haltbarkeit Außenhülle</li>
                </ul>
              </li>
            </ul>
          </Groupecard>

          <Groupecard number="02" title="Stirling-Motor" picture={pic2}>
            <h3>BENEFITS</h3>
            <ul>
              <li>24h Strom aus gespeicherter Wärme</li>
              <li>Für jede Wärmequelle geeignet</li>
            </ul>

            <h3>FAKTEN</h3>
            <ul>
              <li>Niedertemperatur Stirling 150-220°C</li>
              <li>Wärme ► Strom &amp; mechanische Energie</li>
              <li>Nutzt Abwärme &amp; solarthermische Energie</li>
              <li>Kann als Kältemaschine verwendet werden</li>
              <li>7,5kwh / elektrisch 1000W Peak</li>
            </ul>
          </Groupecard>

          <Groupecard number="03" title="Energie-speicher" picture={pic3}>
            <h3>BENEFITS</h3>
            <ul>
              <li>Speichervolumen ► Saisonspeicherung</li>
              <li>Keine giftigen / seltenen Materialien .</li>
              <li>Kein Memory-Effekt ► Kein Kapazitätsverlust</li>
            </ul>

            <h3>FAKTEN</h3>
            <ul>
              <li>Langzeitspeicherung thermische Energie</li>
              <li>Hochtemperaturwärme mit 450°C</li>
              <li>Parallele Wärme &amp; Kälteproduktion</li>
              <li>Leichtbauspiegel mit Foliensegmenten</li>
            </ul>
          </Groupecard>

          <Groupecard number="04" title="Sonnen-kraftwerk" picture={pic4}>
            <h3>FAKTEN</h3>
            <ul>
              <li>Kombination aus Wärmespeicher &amp; Stirling</li>
              <li>Auch in Kombination mit Dampfturbine</li>
              <li>Produktion von Wärme, Kälte &amp; Strom</li>
              <li>24h Stromerzeugung ohne Unterbrechung</li>
            </ul>
          </Groupecard>
          {/** 
          <Groupecard number="05" title="Photolyse Katalysator">
            <h3>BENEFITS</h3>
            <ul>
              <li>------------------------------------</li>
              <li>------------------------------------</li>
              <li>------------------------------------</li>
            </ul>

            <h3>FAKTEN</h3>
            <ul>
              <li>------------------------------------</li>
              <li>------------------------------------</li>
              <li>------------------------------------</li>
              <li>------------------------------------</li>
            </ul>
          </Groupecard>
*/}
          <Groupecard number="05" title="Lichtwellen-Leiter" picture={pic6}>
            <h3>BENEFITS</h3>
            <ul>
              <li>Trennung sicht- &amp; unsichtbare Lichtwellen</li>
              <li>Sichtbares Licht ► Leitung &lt; 60m in Flüssigkeit</li>
              <li>Unsichtbares Licht ► Strom</li>
              <li>Bei großen Anlagen ► Wärme</li>
              <li>Bei Tag: Sonnenlicht im Gebäude</li>
              <li>Bei Nacht: Licht durch erzeugten Strom &amp; LED</li>
            </ul>

            <h3>FAKTEN</h3>
            <ul>
              <li>24 Stunden kostenlose Beleuchtung</li>
              <li>Indoor Gardening ► Büro- &amp; Wohnausstattung</li>
            </ul>
          </Groupecard>

          <Groupecard mainTitle="Energiemodule" picture={pic7}>
            <h3>WAS WIR BIETEN</h3>
            <ul>
              <li>Funktionierende Prototypen</li>
              <li>Erfahrungen aus Langzeitbetrieb</li>
              <li>Netzwerk aus Produktionspartnern</li>
              <li>Ergänzende Baukästen im Ökosystem</li>
              <li>Interessierte Kommune &amp; Landkreis</li>
              <li>Konsortialstruktur &amp; Förderzugänge</li>
              <li>Entwicklerfestival mit Thema Energie</li>
            </ul>

            <h3>WAS WIR SUCHEN</h3>
            <ul>
              <li>Investoren</li>
              <li>Produktionspartner ► Serienproduktion</li>
              <li>Forschungspartner </li>
              <li>Vertriebspartner</li>
            </ul>
          </Groupecard>
        </div>
      </div>
    );
  }
}
export default energie;
