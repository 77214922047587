import React from "react";
import "./triangle.css";
class triangle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triangleClasse: "triangle",
      shapesClasse: "shapes"
    };
  }

  componentDidMount() {
    if (this.props.size === "big") {
      this.setState({
        triangleClasse: "bigtriangle",
        shapesClasse: "bigshapes"
      });
    }
  }

  render() {
    return (
      <div className={this.state.triangleClasse}>
        <div className={this.state.shapesClasse}></div>
        <div className="number">
          <h4>{this.props.number}</h4>
        </div>

        <div className="content">
          <h6>{this.props.title}</h6>
        </div>
      </div>
    );
  }
}
export default triangle;
