import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Formula from "../../compenents/formula/Formula";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="wraper">
          <Formula />
        </div>
      </Modal.Body>
    </Modal>
  );
}

class Popmodel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false
    };
  }

  setModalShow = val => {
    this.setState({
      modalShow: val
    });
  };
  render() {
    return (
      <div>
        <ButtonToolbar>
          <Button variant="warning" onClick={() => this.setModalShow(true)}>
            {this.props.text}
          </Button>
          <MyVerticallyCenteredModal
            show={this.state.modalShow}
            onHide={() => this.setModalShow(false)}
          />
        </ButtonToolbar>
      </div>
    );
  }
}
export default Popmodel;
