import React from "react";

import "./carte.css";

class Carte extends React.Component {
  setImage = () => {
    if (this.props.picture) {
      return <img alt="Back" src={this.props.picture} />;
    }
    return null;
  };

  render() {
    return (
      <div className="carte ">
        <div className="car-image">{this.setImage()}</div>
        <div className="car-content">
          <h2>{this.props.title}</h2>
          {this.props.content}
        </div>
      </div>
    );
  }
}
export default Carte;
