import React from "react";
import "./Picture.css";
class Picture extends React.Component {
  render() {
    return (
      <div className="picture">
        <img height="100%" alt="ub1" width="100%" src={this.props.src} />
      </div>
    );
  }
}
export default Picture;
