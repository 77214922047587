import React from "react";
import { Route } from "react-router-dom";
import "./router.css";

import Contact from "../../compenents/Contact/Contact";
import Home from "../Home/Home";
import Services from "../for_companies/Services";
import About from "../About/About";
import for_tallents from "../for_tallents/for_tallents";
import Real_labs from "../Real_labs/Real_labs";
import energie from "../Energie/eregie";

class Router extends React.Component {
  render() {
    return (
      <div className="router">
        <Route exact path="/" component={Home} />
        <Route exact path="/Contact" component={Contact} />
        <Route exact path="/Services" component={Services} />
        <Route exact path="/for_tallents" component={for_tallents} />
        <Route exact path="/Real_labs" component={Real_labs} />
        <Route exact path="/History" component={History} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Energie" component={energie} />
      </div>
    );
  }
}

export default Router;
