import React from "react";
import "./MyMap.css";
class MyMap extends React.PureComponent {
  render() {
    return (
      <div id="map">
        <iframe
          title="lol"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1498.5054794908192!2d8.339911931872203!3d48.125549301819774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4790c044d831bd59%3A0x238b5cc72af6c1cd!2sBahnhofstra%C3%9Fe%208%2C%2078112%20St.%20Georgen!5e0!3m2!1sfr!2sde!4v1572451035170!5m2!1sfr!2sde"
          width="100%"
          height="450"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
    );
  }
}

export default MyMap;
