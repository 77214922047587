import React from "react";

import { FaRegCopyright } from "react-icons/fa";
import "./Footer.css";

var currYear = new Date().getFullYear();

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <div className="wraper">
          <div id="info">
            <h1>Kontakt</h1>
            <p>
              Bahnhofstraße 8<br />
              78112 St. Georgen, Deutschland <br />
              info@steidinger.audio <br />
              +49 0151 727 88888
            </p>
          </div>

          <div id="copyrigth">
            <i className="fa fa-copyright" aria-hidden="true">
              {" "}
              copyright{" "}
              <span>
                <FaRegCopyright />{" "}
              </span>
              {currYear} steidinger apparatebau, all right reserved
            </i>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
