import React from "react";
import "./Configurator.css";
import { setAnimate } from "./Configurator";
import conf from "../../imgs/configurator/conf.png";
import ar from "../../imgs/configurator/ar.png";
import platform from "../../imgs/configurator/platform.png";
import tree from "../../imgs/configurator/tree.svg";
import star from "../../imgs/configurator/star.svg";
import lego from "../../imgs/configurator/lego.png";
import dam from "../../imgs/configurator/dam.png";
import { NavLink } from "react-router-dom";

class Configurator extends React.Component {
  componentDidMount() {
    setAnimate();
  }

  render() {
    return (
      <div id="configurator" style={{ backgroundImage: `url(${conf})` }}>
        <div id="container">
          <div className="feild1 hovred1"></div>
          <NavLink className="nav-link" exact to="/Energie">
            <div className="feild2 hovred2"></div>
          </NavLink>

          <div className="feild3 hovred3"></div>

          <div id="top">
            <div className="sec1">
              <img src={ar} alt="dual" />
              <h5>Augmented Reality </h5>
            </div>
          </div>

          <div id="left-top">
            <div className="sec1 sec3">
              <img src={platform} alt="platforms" />
              <h5>Configurators &amp; Platforms</h5>
            </div>
          </div>

          <div id="left-bottom">
            <div className="sec3">
              <img src={tree} alt="Tree" />
              <h5> Tree to Superfood </h5>
            </div>
          </div>

          <div id="bottom">
            <div className="sec3 sec2">
              <img src={dam} alt="Dam" />
              <h5>Temporary Architecture</h5>
            </div>
          </div>

          <div id="right-top">
            <div className="sec1 sec2">
              <img src={lego} alt="Lego" />
              <h5>Mass Customization</h5>
            </div>
          </div>

          <div id="right-bottom">
            <div className="sec2">
              <img src={star} alt="star" />
              <h5>Solar energy</h5>
            </div>
          </div>

          <h6 id="med">Medientechnik</h6>
          <h6 id="sol">Energietechnik</h6>
          <h6 id="tre">Kulinarik</h6>
        </div>
      </div>
    );
  }
}
export default Configurator;
