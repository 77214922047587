import React from "react";
import "./App.css";
import Nav from "./compenents/navigationBar/Nav";
import Footer from "./compenents/footer/Footer";
import Router from "./pages/Router/Router";
import Declaration from "./compenents/Declaration/Declaration";
import Impresum from "./compenents/Declaration/Impresum";
import Daten from "./compenents/Declaration//Daten";

function App() {
  return (
    <div className="App container-fluid">
      <Nav />
      <Router />
      <Footer />
      <Declaration />
      <Impresum />
      <Daten />
    </div>
  );
}

export default App;
