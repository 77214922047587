import $ from "jquery.animate";
import { setInterval } from "timers";
let counter = 0;
let hovred = false;
var interval = null;

function setBgAnimation(selector) {
  if (hovred === false) {
    if (counter % 2 === 0) {
      $(selector).animate(
        {
          "background-color": "rgba(8, 178, 243, 0.4)"
        },
        1000,
        "swing",
        function() {}
      );
    } else {
      $(selector).animate(
        {
          "background-color": "rgba(8, 178, 243, 0.2)"
        },
        1000,
        "swing",
        function() {}
      );
    }
    counter++;
  } else clearInterval(interval);
}

function runAnimations() {
  setBgAnimation(".feild1");
}

export function setAnimate() {
  interval = setInterval(function() {
    runAnimations();
  }, 1000);
  $(function() {
    $('[data-toggle="tooltip"]').tooltip();
  });
  $(".hovred1,#med").hover(
    function() {
      hovred = true;
      $("#configurator").finish();
      $(".sec1").css("visibility", "visible");
      $("#configurator").animate(
        {
          transform: " rotateX(-15deg)"
        },
        function() {}
      );
    },
    function() {
      $(".sec1").css("visibility", "hidden");

      $("#configurator").animate(
        {
          transform: " rotate(0deg)"
        },
        function() {}
      );
    }
  );

  $(".hovred2,#sol").hover(
    function() {
      hovred = true;

      $("#configurator").finish();
      $(".sec2").css("visibility", "visible");

      $("#configurator").animate(
        {
          transform: " rotateY(-15deg) rotateX(15deg)"
        },
        function() {}
      );
    },
    function() {
      $(".sec2").css("visibility", "hidden");

      $("#configurator").animate(
        {
          transform: " rotate(0deg)"
        },
        function() {}
      );
    }
  );

  $(".hovred3,#tre").hover(
    function() {
      hovred = true;

      $("#configurator").finish();
      $(".sec3").css("visibility", "visible");

      $("#configurator").animate(
        {
          transform: " rotateY(15deg) rotateX(15deg)"
        },
        function() {}
      );
    },
    function() {
      $(".sec3").css("visibility", "hidden");

      $("#configurator").animate(
        {
          transform: " rotate(0deg)"
        },
        function() {}
      );
    }
  );
}
