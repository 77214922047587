import React from 'react';

class Real_labs extends React.Component{
    render(){
        return(<div>
            <h1>am resl labs page</h1>
        </div>)
    }
}

export default Real_labs;